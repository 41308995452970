<template>
  <div class="partnerships content">
    <h2>Partnerships</h2>

    <div class="partnerships-grid">
      <div
        v-for="partner in partnerships"
        :key="partner.name"
        class="partnerships__logo"
      >
        <img
          :src="`/img/home/partnerships/${partner.logo}`"
          :alt="partner.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerships: [
        {
          name: 'ICMA',
          logo: 'logo-icma@2x.png',
        },
        {
          name: 'NACO',
          logo: 'logo-naco@2x.png',
        },
        {
          name: 'NLC',
          logo: 'logo-nlc@2x.png',
        },
        {
          name: 'Smart City Expo USA',
          logo: 'logo-smart-city-expo-usa@2x.png',
        },
        {
          name: 'Waternow Alliance',
          logo: 'logo-waternow-alliance@2x.png',
        },
        {
          name: 'Alliance for Innovation',
          logo: 'logo-alliance-for-innovation@2x.png',
        },
        {
          name: 'Centre for Public Impact',
          logo: 'logo-centre-for-public-impact@2x.png',
        },
        {
          name: 'Climate Collaborative San Diego Region',
          logo: 'climate-collaborative@2x.png',
        },
        {
          name: 'GMIS International',
          logo: 'logo-gmis-international@2x.png',
        },
        {
          name: 'Green Infrastructure Leadership Exchange',
          logo: 'logo-gi-exchange@2x.png',
        },
        {
          name: 'National Academy of Public Administration',
          logo: 'logo-napa@2x.png',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.partnerships {
  margin: 0 auto;
  padding: 32px 10px;
  text-align: center;
  width: 100%;

  @include bp('sm') {
    padding: 84px 20px;
    max-width: unset;
  }
}

h2 {
  margin: 0 0 30px;
}

.partnerships__logo {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  box-shadow: 0 3px 7px 0 rgba(81, 92, 108, 0.23);
  // width: 214px;
  outline: none;

  @include bp('sm') {
    box-shadow: 0 6px 15px 0 rgba(81, 92, 108, 0.23);
    padding: 16px 32px;
    width: 300px;
  }

  img {
    // width: 150px;
    width: 100%;
    background-color: #ffffff;

    @include bp('xs-sm') {
      width: 236px;
    }
  }
}

// Make a CSS grid that has 2 items per row, and 3 items per row on larger screens
// use 30px spacing between rows and columns
.partnerships-grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 10px;
  grid-column-gap: 10px;

  // this stops the items from having a gap around them
  place-content: start;
  // this moves all the content into the middle of the grid
  justify-content: center;

  @include bp('sm') {
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    max-width: 720px;
  }

  @include bp('lg') {
    max-width: 1080px;
    grid-template-columns: repeat(3, auto);
  }
}
</style>
